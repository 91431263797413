* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  outline: none;
  list-style: none;
}
@import "./reset";
@import "./variables";
.app {
  max-width: 100%;
  overflow: hidden;
}
.container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  @media screen and (max-width: 1600px) {
    max-width: 90%;
  }
}
.section {
  padding: 5rem 0;
  &-blue {
    background: $blue;
    color: #fff;
  }
}
.centred {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: fit-content;
}
.max {
  &-width {
    @for $i from 10 through 100 {
      &-#{$i} {
        max-width: $i * 1% !important;
      }
    }
  }
}
.mg {
  &-tp {
    @for $i from 1 through 10 {
      &-#{$i} {
        margin-top: $i * 1rem !important;
      }
    }
  }
  &-bt {
    @for $i from 1 through 10 {
      &-#{$i} {
        margin-bottom: $i * 1rem !important;
      }
    }
  }
  &-centred {
    @include absoluteCentred;
  }
}
.flex {
  display: flex;
  &-wrap {
    flex-wrap: wrap;
  }
  &-items {
    &-center {
      align-items: center;
    }
  }
}
.roboto {
  @include Roboto;
}
.pirata {
  @include Pirata;
}

.grid {
  display: grid;
  &-gap {
    @for $i from 1 through 5 {
      &-#{$i} {
        grid-gap: $i * 1rem;
      }
    }
  }
  &-col {
    @for $i from 1 through 5 {
      &-#{$i} {
        grid-template-columns: repeat($i, 1fr);
      }
    }
  }
  &-row {
    @for $i from 1 through 5 {
      &-#{$i} {
        grid-template-rows: repeat($i, 1fr);
      }
    }
  }
}
.stretchy-wraper {
  position: relative;
  width: 100%;
  height: 0;
  &.wrapper-50 {
    padding-bottom: 100%;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.link {
  color: #fff;
  text-decoration: underline;
  transition: all 0.25s linear;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    text-decoration: none;
    transition: all 0.25s linear;
  }
}
.text {
  line-height: 2.25;
  font-size: 1rem;
  &-header {
    font-size: 3.25rem;
    @media screen and(max-width: 850px) {
      font-size: 2.75rem;
    }
  }
  &-orange{
    color: #ECA944;
  }
  &-centred {
    text-align: center;
  }
  &-big {
    font-size: 2.25rem;
  }
  &-upper {
    text-transform: uppercase !important;
  }
  &-small {
    font-size: 0.85rem;
  }
  &-white {
    color: white;
  }
  &-golden {
    color: $golden;
  }
  &-gray {
    color: $gray;
  }
}
.before {
  position: relative;
  &::before {
    @include afterElem;
  }
  &-quote {
    &::before {
      background: url(../images/main-quotes.png) no-repeat center;
      width: 2rem;
      height: 2rem;
      background-size: contain;
      left: 0;
      right: 0;
      margin: auto;
      top: -3rem;
    }
  }
}
@import "./sec_main";
@import "./beer";
@import "./about";
@import "./footer";
