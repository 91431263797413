.main {
    background: url(../images/main-bg.jpg) no-repeat;
    background-position: 50% 10%;
    width: 100%;
    min-height: 50vh;
    background-size: cover;
    position: relative;

    &::after {
        content: '';
        background-image: url('../images/white-border.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        bottom: -9rem;
        width: 100%;
        height: 254px;
    }

    h1{
        display: none;
        user-select: none;
        visibility: hidden;
    }
    &.section {
        padding: 14rem 0 10rem;
    }
    .logo {
        width: fit-content;
        height: fit-content;
        margin-left: 5rem;
        @media screen and (max-width: 580px) {
            margin: auto;
        }
        svg {
            position: relative;
            width: 372px;
            object-fit: contain;
            filter: drop-shadow(2px 2px 10px black);
            max-width: 100%;
        }
    }
    .flex {
        gap: 3rem;
        @media screen and (max-width: 1080px) {
            flex-direction: column;
        }
    }
    .text-header {
        flex: 36%;
        line-height: 1.1;
        font-size: 4.5rem;
        @media screen and (max-width: 580px) {
          font-size: 3.5rem;
          filter: drop-shadow(2px 2px 10px black);
        }
    }
    .roboto {
        flex: 64%;
    }
}
