footer {
    position: relative;
    // background: #220605;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     margin-top: -41%;
    //     width: 100%;
    //     height: 0;
    //     background: linear-gradient(0deg, #220605, transparent);
    //     display: block;
    //     z-index: 45;
    //     padding-bottom: 36%;
    //     height: 0;
    //     @media screen and (max-width: 788px) {
    //         padding-bottom: 46%;
    //         margin-top: -56%;
    //     }
    // }
    &::after {
        content: "";
        position: absolute;
        width: 30%;
        height: 68%;
        background: url(../images/after4.png) no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        bottom: 0;
        right: 10%;
        @media screen and (max-width: 980px) {
            width: 40%;
        }
        
    }
    .container {
        position: relative;
        z-index: 50;
        .logo {
            max-width: 200px;
        }
        a {
            color: inherit;
        }
        .flex {
            align-items: flex-end;
            @media screen and (max-width: 980px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }
}
