.bottle-content {
    max-width: 65%;
    margin: auto;
    @media screen and(max-width:1000px) {
        max-width: 100%;
        svg {
            max-width: 100%;
        }
    }
    @media screen and(max-width:580px) {
        .bottle-description {
            flex-wrap: wrap-reverse;
            margin-bottom: 5rem !important;
            &:nth-child(odd) {
                flex-wrap: wrap;
            }
            .animated {
                margin: auto;
            }
            .grid {
                margin: auto;
            }
        }
    }
    .bottle-description {
        transition: all 0.5s;
        &:nth-child(1) {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                right: -33%;
                top: -30%;
                height: 84%;
                width: 50%;
                background: url(../images/after1.png) no-repeat center;
                background-size: contain;
                @media screen and(max-width:1000px) {
                    right: -10%;
                }
            }
        }
        &:nth-child(4) {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                right: -40%;
                top: -30%;
                height: 84%;
                width: 46%;
                background: url(../images/after3.png) no-repeat center;
                background-size: contain;
                @media screen and(max-width:1000px) {
                    right: auto;
                    left: -8%;
                    transform: rotateY(180deg);
                }
            }
        }

        &:nth-child(2) {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: -33%;
                bottom: -16%;
                height: 66%;
                width: 36%;
                background: url(../images/after2.png) no-repeat center;
                background-size: contain;
                @media screen and(max-width:1000px) {
                    left: -10%;
                    bottom: 43%;
                }
                @media screen and(max-width:580px) {
                }
            }
        }
        .grid {
            place-items: center;
            width: fit-content;
        }

        &:nth-child(even) {
            .data {
                display: grid;
                place-items: end;
            }
        }
    }
}
.animated {
    opacity: 1 !important;
}
.animated_item {
    opacity: 1 !important;
    .hochenbrau {
        animation: fromNone 5s ease-in-out infinite both;
        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                animation-delay: 0.05s * $i;
            }
        }
    }
}
.not_animated {
    .hochenbrau {
        animation: none;
    }
}
.bottle-description {
    &:nth-child(odd) {
        .animated_item {
            .bottle {
                animation: rotateLeft 0.3s ease-in-out both;
            }
        }
        .not_animated {
            .bottle {
                animation: back 0.3s ease-in-out both;
            }
        }
    }
    &:nth-child(even) {
        .animated_item {
            .bottle {
                animation: rotateRight 0.3s ease-in-out both;
            }
        }
        .not_animated {
            .bottle {
                animation: none;
            }
        }
    }
}
@keyframes fromNone {
    from {
        opacity: 0;
    }
    30%,
    100% {
        opacity: 1;
    }
}
@keyframes rotateLeft {
    to {
        transform: rotate(-5deg);
        transition: all 0.5s;
    }
}
@keyframes rotateRight {
    to {
        transform: rotate(5deg);
        transition: all 0.5s;
    }
}
@keyframes back {
    to {
        transform: none;
        transition: all 0.5s;
    }
}
