@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pirata+One&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  outline: none;
  list-style: none; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.app {
  max-width: 100%;
  overflow: hidden; }

.container {
  width: 100%;
  max-width: 1440px;
  margin: auto; }
  @media screen and (max-width: 1600px) {
    .container {
      max-width: 90%; } }

.section {
  padding: 5rem 0; }
  .section-blue {
    background: #011e41;
    color: #fff; }

.centred {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.max-width-10 {
  max-width: 10% !important; }

.max-width-11 {
  max-width: 11% !important; }

.max-width-12 {
  max-width: 12% !important; }

.max-width-13 {
  max-width: 13% !important; }

.max-width-14 {
  max-width: 14% !important; }

.max-width-15 {
  max-width: 15% !important; }

.max-width-16 {
  max-width: 16% !important; }

.max-width-17 {
  max-width: 17% !important; }

.max-width-18 {
  max-width: 18% !important; }

.max-width-19 {
  max-width: 19% !important; }

.max-width-20 {
  max-width: 20% !important; }

.max-width-21 {
  max-width: 21% !important; }

.max-width-22 {
  max-width: 22% !important; }

.max-width-23 {
  max-width: 23% !important; }

.max-width-24 {
  max-width: 24% !important; }

.max-width-25 {
  max-width: 25% !important; }

.max-width-26 {
  max-width: 26% !important; }

.max-width-27 {
  max-width: 27% !important; }

.max-width-28 {
  max-width: 28% !important; }

.max-width-29 {
  max-width: 29% !important; }

.max-width-30 {
  max-width: 30% !important; }

.max-width-31 {
  max-width: 31% !important; }

.max-width-32 {
  max-width: 32% !important; }

.max-width-33 {
  max-width: 33% !important; }

.max-width-34 {
  max-width: 34% !important; }

.max-width-35 {
  max-width: 35% !important; }

.max-width-36 {
  max-width: 36% !important; }

.max-width-37 {
  max-width: 37% !important; }

.max-width-38 {
  max-width: 38% !important; }

.max-width-39 {
  max-width: 39% !important; }

.max-width-40 {
  max-width: 40% !important; }

.max-width-41 {
  max-width: 41% !important; }

.max-width-42 {
  max-width: 42% !important; }

.max-width-43 {
  max-width: 43% !important; }

.max-width-44 {
  max-width: 44% !important; }

.max-width-45 {
  max-width: 45% !important; }

.max-width-46 {
  max-width: 46% !important; }

.max-width-47 {
  max-width: 47% !important; }

.max-width-48 {
  max-width: 48% !important; }

.max-width-49 {
  max-width: 49% !important; }

.max-width-50 {
  max-width: 50% !important; }

.max-width-51 {
  max-width: 51% !important; }

.max-width-52 {
  max-width: 52% !important; }

.max-width-53 {
  max-width: 53% !important; }

.max-width-54 {
  max-width: 54% !important; }

.max-width-55 {
  max-width: 55% !important; }

.max-width-56 {
  max-width: 56% !important; }

.max-width-57 {
  max-width: 57% !important; }

.max-width-58 {
  max-width: 58% !important; }

.max-width-59 {
  max-width: 59% !important; }

.max-width-60 {
  max-width: 60% !important; }

.max-width-61 {
  max-width: 61% !important; }

.max-width-62 {
  max-width: 62% !important; }

.max-width-63 {
  max-width: 63% !important; }

.max-width-64 {
  max-width: 64% !important; }

.max-width-65 {
  max-width: 65% !important; }

.max-width-66 {
  max-width: 66% !important; }

.max-width-67 {
  max-width: 67% !important; }

.max-width-68 {
  max-width: 68% !important; }

.max-width-69 {
  max-width: 69% !important; }

.max-width-70 {
  max-width: 70% !important; }

.max-width-71 {
  max-width: 71% !important; }

.max-width-72 {
  max-width: 72% !important; }

.max-width-73 {
  max-width: 73% !important; }

.max-width-74 {
  max-width: 74% !important; }

.max-width-75 {
  max-width: 75% !important; }

.max-width-76 {
  max-width: 76% !important; }

.max-width-77 {
  max-width: 77% !important; }

.max-width-78 {
  max-width: 78% !important; }

.max-width-79 {
  max-width: 79% !important; }

.max-width-80 {
  max-width: 80% !important; }

.max-width-81 {
  max-width: 81% !important; }

.max-width-82 {
  max-width: 82% !important; }

.max-width-83 {
  max-width: 83% !important; }

.max-width-84 {
  max-width: 84% !important; }

.max-width-85 {
  max-width: 85% !important; }

.max-width-86 {
  max-width: 86% !important; }

.max-width-87 {
  max-width: 87% !important; }

.max-width-88 {
  max-width: 88% !important; }

.max-width-89 {
  max-width: 89% !important; }

.max-width-90 {
  max-width: 90% !important; }

.max-width-91 {
  max-width: 91% !important; }

.max-width-92 {
  max-width: 92% !important; }

.max-width-93 {
  max-width: 93% !important; }

.max-width-94 {
  max-width: 94% !important; }

.max-width-95 {
  max-width: 95% !important; }

.max-width-96 {
  max-width: 96% !important; }

.max-width-97 {
  max-width: 97% !important; }

.max-width-98 {
  max-width: 98% !important; }

.max-width-99 {
  max-width: 99% !important; }

.max-width-100 {
  max-width: 100% !important; }

.mg-tp-1 {
  margin-top: 1rem !important; }

.mg-tp-2 {
  margin-top: 2rem !important; }

.mg-tp-3 {
  margin-top: 3rem !important; }

.mg-tp-4 {
  margin-top: 4rem !important; }

.mg-tp-5 {
  margin-top: 5rem !important; }

.mg-tp-6 {
  margin-top: 6rem !important; }

.mg-tp-7 {
  margin-top: 7rem !important; }

.mg-tp-8 {
  margin-top: 8rem !important; }

.mg-tp-9 {
  margin-top: 9rem !important; }

.mg-tp-10 {
  margin-top: 10rem !important; }

.mg-bt-1 {
  margin-bottom: 1rem !important; }

.mg-bt-2 {
  margin-bottom: 2rem !important; }

.mg-bt-3 {
  margin-bottom: 3rem !important; }

.mg-bt-4 {
  margin-bottom: 4rem !important; }

.mg-bt-5 {
  margin-bottom: 5rem !important; }

.mg-bt-6 {
  margin-bottom: 6rem !important; }

.mg-bt-7 {
  margin-bottom: 7rem !important; }

.mg-bt-8 {
  margin-bottom: 8rem !important; }

.mg-bt-9 {
  margin-bottom: 9rem !important; }

.mg-bt-10 {
  margin-bottom: 10rem !important; }

.mg-centred {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }

.flex {
  display: -webkit-flex;
  display: flex; }
  .flex-wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .flex-items-center {
    -webkit-align-items: center;
            align-items: center; }

.roboto {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal; }

.pirata {
  font-family: "Pirata One", cursive;
  font-style: normal;
  font-weight: normal; }

.grid {
  display: grid; }
  .grid-gap-1 {
    grid-gap: 1rem; }
  .grid-gap-2 {
    grid-gap: 2rem; }
  .grid-gap-3 {
    grid-gap: 3rem; }
  .grid-gap-4 {
    grid-gap: 4rem; }
  .grid-gap-5 {
    grid-gap: 5rem; }
  .grid-col-1 {
    grid-template-columns: repeat(1, 1fr); }
  .grid-col-2 {
    grid-template-columns: repeat(2, 1fr); }
  .grid-col-3 {
    grid-template-columns: repeat(3, 1fr); }
  .grid-col-4 {
    grid-template-columns: repeat(4, 1fr); }
  .grid-col-5 {
    grid-template-columns: repeat(5, 1fr); }
  .grid-row-1 {
    grid-template-rows: repeat(1, 1fr); }
  .grid-row-2 {
    grid-template-rows: repeat(2, 1fr); }
  .grid-row-3 {
    grid-template-rows: repeat(3, 1fr); }
  .grid-row-4 {
    grid-template-rows: repeat(4, 1fr); }
  .grid-row-5 {
    grid-template-rows: repeat(5, 1fr); }

.stretchy-wraper {
  position: relative;
  width: 100%;
  height: 0; }
  .stretchy-wraper.wrapper-50 {
    padding-bottom: 100%; }
    .stretchy-wraper.wrapper-50 img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center; }

.link {
  color: #fff;
  text-decoration: underline;
  transition: all 0.25s linear;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto; }
  .link:hover {
    text-decoration: none;
    transition: all 0.25s linear; }

.text {
  line-height: 2.25;
  font-size: 1rem; }
  .text-header {
    font-size: 3.25rem; }
    @media screen and (max-width: 850px) {
      .text-header {
        font-size: 2.75rem; } }
  .text-orange {
    color: #ECA944; }
  .text-centred {
    text-align: center; }
  .text-big {
    font-size: 2.25rem; }
  .text-upper {
    text-transform: uppercase !important; }
  .text-small {
    font-size: 0.85rem; }
  .text-white {
    color: white; }
  .text-golden {
    color: #d19d4e; }
  .text-gray {
    color: #8a8a8a; }

.before {
  position: relative; }
  .before::before {
    content: "";
    position: absolute;
    display: block; }
  .before-quote::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAdCAYAAAA3i0VNAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHJSURBVHgBtZiBbcIwEEUvnYAN6g1ghHSCsgHpBNAJYAO6QegE7QahE8AGzgawwe8ddkQUxcFOjiddbCTy9e8ucuxklAgAw8OSY84h85rjM8uyK42ENWc8LDjevab8rlnzg1JgoYKjQg80Er415yg5Lj2yNkVoETLn2VEiUjlvLoT1HYsSWweynGIw9yZUDG4xzI4S8UlDy+D+CQa3mgYfiZWUCN+zxGNMrFihJnbXNBh+BuMTjxRLXm74lh88pogVKxHHiiJBXGeEE9yCPihmkEbFkUeYtAma8t8tQo8T4qvYpUSgAnDr4Rgs+gqAtIy79LYK4xNvWGlk3KZSTrxBNh30Qm5HMxVJdNkyKOKGprOXi5ickw7r1tyQDpJ8LiZnpIMIvvq5IT1WYnJBerz5UStx4VZJTQzpY7RNPgUxWZMezTmnJj2uYvJMejRaR9LjLCb/SIcrn+6OMuGxJj2j32LyQPc2TeG3K046HG9XXt82mIZFz+4F7r0+hV1X8IDx9L5a4baAl5GaFQVEU42KgYIGgDu3W6RRYWgTDNd6GylkKAK4isYcIyTpTYxmI9p8Vmm3y3J8IWJXHtCV97t069QxVvni9FbvH5bu1CqDpunyAAAAAElFTkSuQmCC) no-repeat center;
    width: 2rem;
    height: 2rem;
    background-size: contain;
    left: 0;
    right: 0;
    margin: auto;
    top: -3rem; }

.main {
  background: url(../../static/media/main-bg.4420b689.jpg) no-repeat;
  background-position: 50% 10%;
  width: 100%;
  min-height: 50vh;
  background-size: cover;
  position: relative; }
  .main::after {
    content: '';
    background-image: url(../../static/media/white-border.ba015f01.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: -9rem;
    width: 100%;
    height: 254px; }
  .main h1 {
    display: none;
    -webkit-user-select: none;
            user-select: none;
    visibility: hidden; }
  .main.section {
    padding: 14rem 0 10rem; }
  .main .logo {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: 5rem; }
    @media screen and (max-width: 580px) {
      .main .logo {
        margin: auto; } }
    .main .logo svg {
      position: relative;
      width: 372px;
      object-fit: contain;
      -webkit-filter: drop-shadow(2px 2px 10px black);
              filter: drop-shadow(2px 2px 10px black);
      max-width: 100%; }
  .main .flex {
    grid-gap: 3rem;
    gap: 3rem; }
    @media screen and (max-width: 1080px) {
      .main .flex {
        -webkit-flex-direction: column;
                flex-direction: column; } }
  .main .text-header {
    -webkit-flex: 36% 1;
            flex: 36% 1;
    line-height: 1.1;
    font-size: 4.5rem; }
    @media screen and (max-width: 580px) {
      .main .text-header {
        font-size: 3.5rem;
        -webkit-filter: drop-shadow(2px 2px 10px black);
                filter: drop-shadow(2px 2px 10px black); } }
  .main .roboto {
    -webkit-flex: 64% 1;
            flex: 64% 1; }

.bottle-content {
  max-width: 65%;
  margin: auto; }
  @media screen and (max-width: 1000px) {
    .bottle-content {
      max-width: 100%; }
      .bottle-content svg {
        max-width: 100%; } }
  @media screen and (max-width: 580px) {
    .bottle-content .bottle-description {
      -webkit-flex-wrap: wrap-reverse;
              flex-wrap: wrap-reverse;
      margin-bottom: 5rem !important; }
      .bottle-content .bottle-description:nth-child(odd) {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
      .bottle-content .bottle-description .animated {
        margin: auto; }
      .bottle-content .bottle-description .grid {
        margin: auto; } }
  .bottle-content .bottle-description {
    transition: all 0.5s; }
    .bottle-content .bottle-description:nth-child(1) {
      position: relative; }
      .bottle-content .bottle-description:nth-child(1)::before {
        content: "";
        position: absolute;
        right: -33%;
        top: -30%;
        height: 84%;
        width: 50%;
        background: url(../../static/media/after1.faba4e65.png) no-repeat center;
        background-size: contain; }
        @media screen and (max-width: 1000px) {
          .bottle-content .bottle-description:nth-child(1)::before {
            right: -10%; } }
    .bottle-content .bottle-description:nth-child(4) {
      position: relative; }
      .bottle-content .bottle-description:nth-child(4)::before {
        content: "";
        position: absolute;
        right: -40%;
        top: -30%;
        height: 84%;
        width: 46%;
        background: url(../../static/media/after3.bd36104d.png) no-repeat center;
        background-size: contain; }
        @media screen and (max-width: 1000px) {
          .bottle-content .bottle-description:nth-child(4)::before {
            right: auto;
            left: -8%;
            -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg); } }
    .bottle-content .bottle-description:nth-child(2) {
      position: relative; }
      .bottle-content .bottle-description:nth-child(2)::before {
        content: "";
        position: absolute;
        left: -33%;
        bottom: -16%;
        height: 66%;
        width: 36%;
        background: url(../../static/media/after2.fd93ab01.png) no-repeat center;
        background-size: contain; }
        @media screen and (max-width: 1000px) {
          .bottle-content .bottle-description:nth-child(2)::before {
            left: -10%;
            bottom: 43%; } }
    .bottle-content .bottle-description .grid {
      place-items: center;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
    .bottle-content .bottle-description:nth-child(even) .data {
      display: grid;
      place-items: end; }

.animated {
  opacity: 1 !important; }

.animated_item {
  opacity: 1 !important; }
  .animated_item .hochenbrau {
    -webkit-animation: fromNone 5s ease-in-out infinite both;
            animation: fromNone 5s ease-in-out infinite both; }
    .animated_item .hochenbrau:nth-child(1) {
      -webkit-animation-delay: 0.05s;
              animation-delay: 0.05s; }
    .animated_item .hochenbrau:nth-child(2) {
      -webkit-animation-delay: 0.1s;
              animation-delay: 0.1s; }
    .animated_item .hochenbrau:nth-child(3) {
      -webkit-animation-delay: 0.15s;
              animation-delay: 0.15s; }
    .animated_item .hochenbrau:nth-child(4) {
      -webkit-animation-delay: 0.2s;
              animation-delay: 0.2s; }
    .animated_item .hochenbrau:nth-child(5) {
      -webkit-animation-delay: 0.25s;
              animation-delay: 0.25s; }
    .animated_item .hochenbrau:nth-child(6) {
      -webkit-animation-delay: 0.3s;
              animation-delay: 0.3s; }
    .animated_item .hochenbrau:nth-child(7) {
      -webkit-animation-delay: 0.35s;
              animation-delay: 0.35s; }
    .animated_item .hochenbrau:nth-child(8) {
      -webkit-animation-delay: 0.4s;
              animation-delay: 0.4s; }
    .animated_item .hochenbrau:nth-child(9) {
      -webkit-animation-delay: 0.45s;
              animation-delay: 0.45s; }
    .animated_item .hochenbrau:nth-child(10) {
      -webkit-animation-delay: 0.5s;
              animation-delay: 0.5s; }

.not_animated .hochenbrau {
  -webkit-animation: none;
          animation: none; }

.bottle-description:nth-child(odd) .animated_item .bottle {
  -webkit-animation: rotateLeft 0.3s ease-in-out both;
          animation: rotateLeft 0.3s ease-in-out both; }

.bottle-description:nth-child(odd) .not_animated .bottle {
  -webkit-animation: back 0.3s ease-in-out both;
          animation: back 0.3s ease-in-out both; }

.bottle-description:nth-child(even) .animated_item .bottle {
  -webkit-animation: rotateRight 0.3s ease-in-out both;
          animation: rotateRight 0.3s ease-in-out both; }

.bottle-description:nth-child(even) .not_animated .bottle {
  -webkit-animation: none;
          animation: none; }

@-webkit-keyframes fromNone {
  from {
    opacity: 0; }
  30%,
  100% {
    opacity: 1; } }

@keyframes fromNone {
  from {
    opacity: 0; }
  30%,
  100% {
    opacity: 1; } }

@-webkit-keyframes rotateLeft {
  to {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    transition: all 0.5s; } }

@keyframes rotateLeft {
  to {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    transition: all 0.5s; } }

@-webkit-keyframes rotateRight {
  to {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    transition: all 0.5s; } }

@keyframes rotateRight {
  to {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    transition: all 0.5s; } }

@-webkit-keyframes back {
  to {
    -webkit-transform: none;
            transform: none;
    transition: all 0.5s; } }

@keyframes back {
  to {
    -webkit-transform: none;
            transform: none;
    transition: all 0.5s; } }

.about {
  background: url(../../static/media/bearfoam.49d84906.png) no-repeat;
  background-size: cover;
  background-position: 50% 0;
  padding-top: 27rem;
  padding-bottom: 0;
  position: relative;
  z-index: 50; }
  .about.section {
    padding-top: 27rem; }
    @media (max-width: 768px) {
      .about.section {
        padding-top: 2rem; } }
  @media screen and (max-width: 1200px) {
    .about .grid-col-3 {
      grid-template-columns: 45% 48%;
      grid-gap: 5rem 3rem;
      grid-template-areas: "first sec" "first thrd"; } }
  @media screen and (max-width: 1200px) and (max-width: 1200px) {
    .about .grid-col-3 .animated:nth-child(1) {
      grid-area: first; }
    .about .grid-col-3 .animated:nth-child(2) {
      grid-area: sec; }
    .about .grid-col-3 .animated:nth-child(3) {
      grid-area: thrd; } }
  @media screen and (max-width: 788px) {
    .about .grid-col-3 {
      display: block; } }
  .about .animated {
    height: 100%; }
  .about .about-item {
    height: 100%;
    position: relative; }
    @media screen and (max-width: 788px) {
      .about .about-item {
        max-width: 90%;
        margin: 0 auto 5rem;
        width: 440px; } }
    .about .about-item::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      box-shadow: 21px 22px 31px rgba(12, 26, 62, 0.12);
      border-radius: 15px;
      top: -1rem;
      border-radius: 15px;
      -webkit-transform: skew(0deg, 5deg);
              transform: skew(0deg, 5deg); }
    .about .about-item .content {
      background: white;
      box-shadow: 21px 22px 31px rgba(12, 26, 62, 0.12);
      border-radius: 15px;
      padding: 2rem;
      display: grid;
      place-items: center;
      position: relative;
      height: 100%; }
  .about .rounded-icon {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: relative;
    display: grid;
    place-items: center;
    background: #011e41;
    position: absolute;
    z-index: 10;
    top: calc(-1rem - 40px);
    left: -20px; }
    .about .rounded-icon img {
      max-width: 60%;
      max-height: 60%;
      object-fit: contain; }

footer {
  position: relative; }
  footer::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 68%;
    background: url(../../static/media/after4.ce19364d.png) no-repeat;
    background-position: 50% 100%;
    background-size: contain;
    bottom: 0;
    right: 10%; }
    @media screen and (max-width: 980px) {
      footer::after {
        width: 40%; } }
  footer .container {
    position: relative;
    z-index: 50; }
    footer .container .logo {
      max-width: 200px; }
    footer .container a {
      color: inherit; }
    footer .container .flex {
      -webkit-align-items: flex-end;
              align-items: flex-end; }
      @media screen and (max-width: 980px) {
        footer .container .flex {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: flex-start;
                  align-items: flex-start; } }

