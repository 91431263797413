.about {
    background: url(../images/bearfoam.png) no-repeat;
    background-size: cover;
    background-position: 50% 0;
    padding-top: 27rem;
    padding-bottom: 0;
    position: relative;
    z-index: 50;
    &.section {
        padding-top: 27rem;

        @media (max-width: 768px) {
            padding-top: 2rem;
        }
    }
    .grid-col-3 {
        @media screen and (max-width: 1200px) {
            grid-template-columns: 45% 48%;
            grid-gap: 5rem 3rem;
            grid-template-areas:
                "first sec"
                "first thrd";
                .animated{
                    @media screen and (max-width: 1200px) {
                        &:nth-child(1) {
                            grid-area: first;
                        }
                        &:nth-child(2) {
                            grid-area: sec;
                        }
                        &:nth-child(3) {
                            grid-area: thrd;
                        }
                    }
                }
        }
        @media screen and (max-width: 788px) {
            display: block;
        }
    }
    .animated{
        height: 100%;
    }
    .about-item {
        height: 100%;
        position: relative;
       
        @media screen and (max-width: 788px) {
            max-width: 90%;
            margin: 0 auto 5rem;
            width: 440px;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 1);
            box-shadow: 21px 22px 31px rgba(12, 26, 62, 0.12);
            border-radius: 15px;
            top: -1rem;
            border-radius: 15px;
            transform: skew(0deg, 5deg);
        }
        .content {
            background: rgba(255, 255, 255, 1);
            box-shadow: 21px 22px 31px rgba(12, 26, 62, 0.12);
            border-radius: 15px;
            padding: 2rem;
            display: grid;
            place-items: center;
            position: relative;
            height: 100%;
        }
    }
    .rounded-icon {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        position: relative;
        display: grid;
        place-items: center;
        background: #011e41;
        position: absolute;
        z-index: 10;
        top: calc(-1rem - 40px);
        left: -20px;

        img {
            max-width: 60%;
            max-height: 60%;
            object-fit: contain;
        }
    }
}
